/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých filtrovaných faktur
 */
export const GET_INVOICES = gql`
    query AllFilteredInvoices($offset:Int, $limit: Int,$dateFrom:String,$dateTo:String,$selectedDomains:[ID],$selectedServices:[ID],$lang:String){
        allFilteredInvoices(offset:$offset,limit:$limit,dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices){
            invoiceID
            invoiceNumber
            name
            surname
            email
            tel 
            dateAddStr
            dueDateStr
            totalPrice
            domainID
            currency
        }
        allDomains{
            domainID
            domainName
        }
        allServices(lang:$lang){
            name
            serviceID
        }
        totalInvoicesPrice(dateFrom:$dateFrom,dateTo:$dateTo,selectedDomains:$selectedDomains,selectedServices:$selectedServices)
        
    }
`;

export const EXPORT_INVOICE = gql`
mutation ExportInvoice($invoiceID:ID, $domainID: ID){
    exportInvoice(invoiceID:$invoiceID,domainID:$domainID)  
}
`;

export const EXPORTED_INVOICES_TO_ZIP = gql`
mutation ExportedInvoicesToZip($invoices:[ID]){
    exportedInvoicesToZip(invoices:$invoices)  
}
`;


