/*
    Přidání štítku
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_ARTICLE} from '../Queries/article';
import { withApollo } from 'react-apollo'; 
import ArticleLib from '../Library/article';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import RecommendedArticles from './RecommendedArticles';
import ArticleMarketings from './ArticleMarketings';
import ArticleServices from './ArticleServices';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import LinkSelector from '../../Content/Components/LinkSelector';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';



registerLocale('cs', cs);

const INITIAL_STATE = {
    apolloError:"",
    selectedArticleID:null,
    mainPhoto:"",
    selectedTab:1,
    showPublishNotifi:false,
    showUnPublishNotifi:false,
    addArticle:null,
    formData:{
        selectedLang:"cz",
        articleID:0,
        sendNewsletter:true,
        alone:false,
        photo:null,
        generateRandomRecommended:true,
        linkIDs:[],
        langs:[],
    },
    allLanguageMutations:[]
}

class AddEditArticle extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.articleLib = new ArticleLib(this); 
    }

    componentDidMount(){

        this.articleLib.getData();
        
    }
    
    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }
    showPublishNotifi = (addArticle) =>{
        this.setState({
            addArticle,
            showPublishNotifi:true
        });
    }
    saveAndPublish = (result) => {
        if(result){

            this.setState({publishing:true});
            this.articleLib.addArticle(this.state.addArticle, async (status,responseData) => {
                
                if(status){  
                
                    this.articleLib.publishArticle(this.state.selectedArticleID,this.state.formData.selectedLang,this.props.listVariables);                   

                }
            })
            
        }
        this.setState({
            showPublishNotifi:false
        });
    }

    showUnPublishNotifi = () =>{
        this.setState({
            showUnPublishNotifi:true
        });
    }
    unpublish = (result) => {
        if(result){
           this.articleLib.unpublishArticle(this.state.selectedArticleID,this.state.formData.selectedLang,this.props.listVariables);                   
        }
        this.setState({
            showUnPublishNotifi:false
        });
    }

    setSelectedLinks = (linkIDs) => {  
        this.setState({formData:{...this.state.formData,linkIDs}});
    }
    
    setGenerateRandomRecommendedValue = (generateRandomRecommended) => {        
        this.setState({formData:{...this.state.formData,generateRandomRecommended}});
    }

    render(){

        const {apolloError,mainPhoto,allLanguageMutations,selectedTab,selectedArticleID,showPublishNotifi,showUnPublishNotifi} = this.state;
        var {listVariables} = this.props;


        return(

            <Mutation 
                mutation={ADD_EDIT_ARTICLE}
                update = {async (cache, response) => {

                    this.articleLib.updateList(cache,response,selectedArticleID,listVariables);
                    
                    let notify = new Notification();

                    if(selectedArticleID && selectedArticleID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                    //this.props.openCloseModal(false);

                }}
                onCompleted = {(response) => {
                    if(selectedArticleID && selectedArticleID != 0){
                        this.props.openCloseModal(false);
                    }else{
                        
                        this.setState({selectedArticleID:response.addEditArticle.articleID,formData:{...this.state.formData, articleID:response.addEditArticle.articleID}});
                    
                    }
                    
                }}
                
            >
            {
                (addArticle,{data,loading,error}) => {

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    const {selectedLang,generateRandomRecommended,linkIDs,sendNewsletter,alone} = this.state.formData;
                    
                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedArticleID && selectedArticleID != 0 ? "Úprava článku" : "Přidání článku")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.articleLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {error || apolloError ?
                                        <Error text={error || apolloError} />
                                    :
                                    <div>
                                        
                                        {!loading ?
                                            <Fragment>  
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 2 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,2)}}>Doporučené články</a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 3 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,3)}}>Marketing</a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a className={(selectedTab == 4 ? "nav-link active" : (selectedArticleID ? 'nav-link' : 'nav-link disabled'))} href="#prices" onClick={(e) => {e.preventDefault();if(selectedArticleID)this.showTab(e,4)}}>Služby</a>
                                                    </li>    
                                                </ul>   

                                                
                                                {selectedTab == 1 &&   
                                                    <div>                                                       
                                                        <div className="form-group input-group">
                                                            <div className="custom-file">
                                                                
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="customFile"
                                                                    name="file"
                                                                    required
                                                                    multiple
                                                                    onChange={(e) => this.articleLib.openImage(e)}
                                                                />
                                                                <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                            </div>
                                                        </div>
                                                        
                                                        {mainPhoto &&
                                                            <div className="row">
                                                                <div className="col-12 col-sm-4 mb-4">
                                                                    <img className = "maxwidth-100" src={mainPhoto} />
                                                                </div>
                                                                
                                                                <div className="col-12 col-sm-8">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">Nadpis článku  ({selectedLang})</label>
                                                                                        <div className="form-group">
                                                                                            <input className="form-control" type="text" name="title" value={this.articleLib.getLangValue("title")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>  
                                                                            </div>  
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label className="input-label">Datum zveřejnění  ({selectedLang})</label>
                                                                                        <div className="form-group">
                                                                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={this.articleLib.getLangValue("publishDate")} onChange={date => this.articleLib.setPublishDate(date)} />
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>
                                                                                {selectedArticleID != 0 &&
                                                                                    <div className="col-6"> 
                                                                                        <div className="form-group">
                                                                                            <label className="input-label">&nbsp;</label>
                                                                                            <div className="form-group">
                                                                                                {!this.articleLib.articlePublished() ?
                                                                                                    <button type="button" className="btn btn-primary " onClick={() => this.showPublishNotifi(addArticle)}>Uložit a publikovat</button>
                                                                                                    :
                                                                                                    <>
                                                                                                    <div className="bold fs12 green">Článek již byl publikován</div>
                                                                                                    <button type="button" className="btn btn-warning" style={{padding:'4px'}} onClick={() => this.showUnPublishNotifi()}>Odpublikovat</button>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>  
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {selectedArticleID != 0 &&
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" id="toNewsletter" name="sendNewsletter" checked = {sendNewsletter} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                    <label className="form-check-label" htmlFor="toNewsletter">Při publikování zaslat newsletter</label>
                                                                                </div>
                                                                            }

                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" id="alone" name="alone" checked = {alone} onChange={(e) => this.articleLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="alone">Osamocený článek</label>
                                                                            </div>
                                                                            
                                                                        </div> 
                                                                        <div className="col-12 col-sm-6">
                                                                            <label className="input-label">Přiřadit odkazu</label>
                                                                            <LinkSelector allArticleLinks={linkIDs}  setSelectedLinks={this.setSelectedLinks}/>                                                                            
                                                                        </div>   
                                                                    </div>                                                   
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Perex článku ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <Editor
                                                                                name="perex"
                                                                                apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                                value={this.articleLib.getLangValue("perex")}
                                                                                init={{
                                                                                    plugins: 'link code paste emoticons',
                                                                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | formatselect | link | blockquote | emoticons | code',
                                                                                    paste_as_text: true,
                                                                                    language_url : '/langs/cs.js',
                                                                                    language: "cs"
                                                                                }}
                                                                                onEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"perex")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="input-label">Text článku ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <TinyMCEEditor onChange={(content,type) => this.articleLib.formLangHandleEditor(content,type)} initialValue={this.articleLib.getLangValue("text")} />           
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="input-label">Marketingový text ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <Editor
                                                                                name="marketingText"
                                                                                apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                                value={this.articleLib.getLangValue("marketingText")}
                                                                                init={{
                                                                                    plugins: 'link code paste emoticons',
                                                                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | formatselect | link | blockquote | emoticons | code',
                                                                                    language_url : '/langs/cs.js',
                                                                                    language: "cs",
                                                                                    paste_as_text: true,
                                                                                }}
                                                                                onEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"marketingText")}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="card main">
                                                                        <div className="card-header d-flex align-items-center">
                                                                            SEO
                                                                        </div>

                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-6">
                                                                                    <label className="input-label">Meta nadpis  ({selectedLang})</label>
                                                                                    <div className="form-group">
                                                                                        <input className="form-control" type="text" name="metaTitle" value={this.articleLib.getLangValue("metaTitle")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    </div>
                                                                                </div>  
                                                                                <div className="col-12 col-sm-6">
                                                                                    <label className="input-label">Meta klíčová slova  ({selectedLang})</label>
                                                                                    <div className="form-group">
                                                                                        <input className="form-control" type="text" name="metaKeywords" value={this.articleLib.getLangValue("metaKeywords")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    </div>
                                                                                </div> 
                                                                                <div className="col-12">
                                                                                    <label className="input-label">Meta popis ({selectedLang})</label>
                                                                                    <div className="input-group">
                                                                                        <input className="form-control" type="text" name="metaDescription" value={this.articleLib.getLangValue("metaDescription")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                        
                                                                                        <div className="input-group-append">
                                                                                            <span className={"input-group-text " + (this.articleLib.getCharCount("metaDescription") > 160 ? 'red' : '')}>{this.articleLib.getCharCount("metaDescription")}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }                                                    
                                                    </div>
                                                }
                                                {selectedTab == 2 &&  
                                                    <RecommendedArticles setGenerateRandomRecommendedValue={this.setGenerateRandomRecommendedValue} generateRandomRecommended={generateRandomRecommended} articleID={selectedArticleID} />
                                                }
                                                {selectedTab == 3 &&  
                                                    <ArticleMarketings articleID={selectedArticleID} />
                                                }
                                                {selectedTab == 4 &&  
                                                    <ArticleServices articleID={selectedArticleID} />
                                                }
  
                                            </Fragment>

                                        :
                                        <Loading />  
                                        }  

                                    </div>
                                    }
               
                                </div>
                                <div className="modal-footer">                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.articleLib.addArticle(addArticle)}>{(selectedArticleID && selectedArticleID != 0 ? "Uložit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>

                                {showPublishNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete publikovat zvolený článek ?'} callback={this.saveAndPublish} /> }	
                                {showUnPublishNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete odpublikovat zvolený článek ?'} callback={this.unpublish} /> }
                                
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditArticle);