/*
    Formulář pro přihlášení se do adminu
*/
import React,{ Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../../../Public/Images/logo.png';
import emailIcon from '../../../Public/Images/email.svg';
import key from '../../../Public/Images/key.svg';
import {LOGIN_USER} from '../Queries/login';
import {Mutation,Query,withApollo} from 'react-apollo';
import {SERVER_URL} from '../../../Config/index';
import {GET_SETTINGS} from '../../Settings/Queries/web';

const INITIAL_STATE = {	 
    email: '',
	password: '',
	error: null
};

class Login extends Component {
		
  constructor(props){
	   	
	  super(props);
	  
      this.checkLogin = this.checkLogin.bind(this);
	  this.setError  = this.setError.bind(this);
	  this.state = { ...INITIAL_STATE };
	  
  }	
  
  checkLogin(adminLogin){
	    
	  const {email,password} = this.state;
	  	  
	  if(password !== '' && email !== ''){
		adminLogin({variables:{email,password}});  
		
	  }else{
		  this.setState({'error':'Nevyplnili jste email nebo heslo.'});
		  setTimeout(() => this.setState({'error':''}),3000);
	  }

  }

  setError(error){
      this.setState({'error':error});
      setTimeout(() => this.setState({'error':''}),3000);
  }

  render() {

	const {email,password,error} = this.state;  
	  
    return (
		
		<Query
			query={GET_SETTINGS}
		>
			{({data,loading,err}) => {

				if(loading)return "";

				return(
					<div id="login" className="admin">
						<div className="logo">
							<div><img alt="rea shop" src={SERVER_URL + "/Public/Images/" + data.settings.logo} /></div>
							<div>{data.settings.webName}</div>
							<div className="bold">Admin</div>
						</div>
						<div className="panel">
							<div className="inner-panel">
								<form onSubmit={this.onSubmit}>
									<div className="form-group">
										
										<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text" ><img alt="" src={emailIcon} /></span>
										</div>
										<input
											onChange={event => this.setState({'email':event.target.value})}
											value={email}
											type="text" className="form-control" placeholder="Uživatelské jméno" aria-label="Username" />
										</div>
				
									</div>
									<div className="form-group">
																
										<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text" ><img alt="" src={key} /></span>
										</div>
										<input
											onChange={event => this.setState({'password':event.target.value})}
											value={password}
											type="password" placeholder="Heslo" className="form-control" aria-label="Password" />
										</div>
										
									</div>
									
									<div className="form-group">
									
										<Mutation
											mutation={LOGIN_USER}
											onCompleted={(data) => {
												if(!data.adminLogin.adminUser)this.setError("Zadali jste špatné uživatelské jméno a heslo.");
												else{
													localStorage.setItem('token', data.adminLogin.loginToken);
													localStorage.setItem('loggedUser', data.adminLogin.adminUser.name + " " + data.adminLogin.adminUser.surname);
													localStorage.setItem('loginPlace','3fdc94bf1dd7dccf3d202a768df0a1eb');
													this.props.client.writeData({ data: { isLoggedIn: true,loginPlace: "3fdc94bf1dd7dccf3d202a768df0a1eb"} });
													
													this.props.history.push('/content/videos');
												}
											}}								
											onError = {(err) => {
												console.log(err);
												/*const helper = new Helper();
												this.setError(helper.getApolloErrorText(err));*/
											}}
											>
											{(adminLogin, { loading, error }) => {
												// this loading state will probably never show, but it's helpful to
												// have for testing
												if (loading) return "";

												return <button type="button" onClick={() => this.checkLogin(adminLogin)} className="btn btn-primary btn-blue btn-block">Přihlásit se</button>;
											}}
										</Mutation>
									</div>
									{false &&
										<div className="text-center">
											<Link to='/reset-password'>Zapomněli jste heslo?</Link>
										</div>
									}
									{error && <div className="alert alert-danger mt-4 mb-0">{error}</div>}
								</form>
								<div className="copy-tag">©2019 1vision</div>
							</div>
						</div>
					</div>
				)
			}
			
  			}
		</Query>
    );
  }
}

export default withApollo(Login);
