/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_LINK,GET_LINKS,GET_LINK} from '../Queries/index.js';
import SelectSubLink from './SelectSubLink';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import TinyMCEEditor from '../../TinyMCEFileManager/Components/TinyMCEEditor';
import LinkLib from '../Library/link';

const INITIAL_STATE = {
    err:"",
    allLanguageMutations:[],
    formData:{
        selectedLang:"cz",
        active:1,
        parentID:"0",
        inHeader:1,
        inFooter:0,
        isBlog:0,
        linkID:0,
        langs:[]
    }
}

class AddEditLink extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.linkLib = new LinkLib(this); 
    }

    componentDidMount = () => {
        
        this.linkLib.getData();
    }


    render(){

        var {err,allLanguageMutations} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_LINK}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_LINKS,
                        variables:{lang:"cz"}
                    }]
                }}
                update = {async (cache, response) => {

                    let notify = new Notification();

                    if(this.props.selectedLinkID && this.props.selectedLinkID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditLink,{loading, error}) => {

                    const {selectedLang,parentID,active,inHeader,inFooter,isBlog} = this.state.formData;
                    const {selectedLinkID} = this.props;

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedLinkID && selectedLinkID != 0 ? "Úprava odkazu" : "Přidání odkazu")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.linkLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">

                                                <div className="col-12 col-sm-3">
                                                    <label className="input-label">Aktivní</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="active" id="active1" value="1" checked={(active == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="active1">
                                                                Ano
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="active" id="active2" value="0" checked={(active == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="active2">
                                                                Ne
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <label className="input-label">Zobrazit v hlavičce</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inHeader" id="inHeader1" value="1" checked={(inHeader == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="inHeader1">
                                                                Ano
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inHeader" id="inHeader2" value="0" checked={(inHeader == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="inHeader2">
                                                                Ne
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <label className="input-label">Zobrazit v patičce</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inFooter" id="inFooter1" value="1" checked={(inFooter == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="inFooter1">
                                                                Ano
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="inFooter" id="inFooter2" value="0" checked={(inFooter == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="inFooter2">
                                                                Ne
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="col-12 col-sm-3">
                                                    <label className="input-label">Zobrazit články (blog,produkty,...)</label>
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isBlog" id="isBlog1" value="1" checked={(isBlog == 1 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="isBlog1">
                                                                Ano
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="isBlog" id="isBlog2" value="0" checked={(isBlog == 0 ?  true : false)} onChange={(e) => this.linkLib.formHandle(e)} />
                                                            <label className="form-check-label" htmlFor="isBlog2">
                                                                Ne
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>                                                                                                                         
                                                <div className="col-12 col-sm-6">
                                                    <label className="input-label">Název odkazu ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="name" value={this.linkLib.getLangValue("name")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label className="input-label">Nadřazený odkaz</label>
                                                    <div className="form-group">
                                                        
                                                        <Query query={GET_LINKS} errorPolicy="all" variables={{lang:"cz"}}>
                                                            {
                                                                ({data,loading,error}) => {

                                                                    if(loading) return (<Loading />);
                                                                    if(error){
                                                                        const helper = new Helper(); 
                                                                        return (<Error text={helper.getApolloErrorText(error)} />);
                                                                    }

                                                                    return(
                                                                        <select value={parentID} className="form-control" name="parentID" onChange={(e) => this.linkLib.formHandle(e)}>

                                                                            <option key={0} value={0}> hlavní odkaz</option>

                                                                            <SelectSubLink links={data.allLinks} />
                                                                            
                                                                        </select>
                                                                    );
                                                                }
                                                            }
                                                        </Query>

                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label className="input-label">Vlastní URL adresa ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="ownUrl" value={this.linkLib.getLangValue("ownUrl")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                {false &&
                                                    <div className="col-12">
                                                        <label className="input-label">Obsah odkazu  ({selectedLang})</label>
                                                        <div className="form-group">
                                                            
                                                        <TinyMCEEditor onChange={null} initialValue={null} />
                                                            
                                                                                                            
                                                        </div>
                                                    </div>  
                                                }

                                                <div className="col-12">
                                                    <div className="card main">

                                                        <div className="card-header d-flex align-items-center">
                                                            SEO
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <label className="input-label">Meta nadpis ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="metaTitle" value={this.linkLib.getLangValue("metaTitle")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <label className="input-label">Meta klíčová slova ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <input className="form-control" type="text" name="metaKeywords" value={this.linkLib.getLangValue("metaKeywords")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                    </div>
                                                                </div>
                                                                 
                                                                <div className="col-12">
                                                                    <label className="input-label">Meta popis ({selectedLang})</label>
                                                                    <div className="input-group">
                                                                        <input className="form-control" type="text" name="metaDescription" value={this.linkLib.getLangValue("metaDescription")} onChange={(e) => this.linkLib.formLangHandle(e)} />
                                                                        
                                                                        <div className="input-group-append">
                                                                            <span className={"input-group-text " + (this.linkLib.getCharCount("metaDescription") > 160 ? 'red' : '')}>{this.linkLib.getCharCount("metaDescription")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.linkLib.addLink(addEditLink)}>{(this.props.selectedLinkID && this.props.selectedLinkID != 0 ? "Upavit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditLink);