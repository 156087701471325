/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_INVOICES} from '../Queries/invoice.js';
import notes from '../../../Public/Images/notes.svg';
import Helper from '../../../Library/helper';
import {SERVER_URL} from '../../../Config/index';
import moment from 'moment';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import InvoiceLib from '../Library/invoice';
import Modal from 'react-modal';

registerLocale('cs', cs);

const INITIAL_STATE = {
    
    showExport:false,
    loadingExport:true,
    progresPercentage:0,
    invoiceCount:0,
    completedCount:0,
    zipName:"",
    invoiceListVariables:{
        limit:50,
        offset:0,
        lang:"cz",
        dateFrom: moment().subtract(2,'months').startOf("day").toDate(),
        dateTo:moment().endOf("day").toDate(),
        selectedDomains:[],
        selectedServices:[],
        //dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD 00:00:00"),
        //dateTo:moment().format("YYYY-MM-DD 23:59:59"),
    }
}

class Customer extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
      
    }

    componentDidMount(){
        this.invoiceLib = new InvoiceLib(this);
    }

    closeExport(){
        this.setState({showExport:false,loadingExport:true});
    }

    render(){

        const {invoiceListVariables,showExport,progresPercentage,invoiceCount,completedCount,loadingExport,zipName} = this.state;

        console.log(invoiceListVariables);

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        
                        <Query 
                            query={GET_INVOICES}
                            variables = {invoiceListVariables}
                            fetchPolicy = {'network-only'}
                        >
                            {({data,loading,error,fetchMore }) => {
                                
                                if(loading) return (<Loading />);
                                if(error){
                                    const helper = new Helper(); 
                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                }

                                return(
                                    <div className="card main">
                                        <div className="card-header">
                                            
                                            <div>
                                                <div>
                                                    <strong>Od: </strong>
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateFrom} onChange={date => this.invoiceLib.setDateFrom(date)} />
                                                    <strong> Do: </strong>
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateTo} onChange={date => this.invoiceLib.setDateTo(date)} />
                                                    <button onClick={() => this.invoiceLib.invoiceExport()} className="btn btn-primary ml-2">Export faktur</button>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    
                                                    {data.allDomains && data.allDomains.map((item,index) => {
                                                        
                                                        var checked = false;
                                                        for(let i in invoiceListVariables.selectedDomains){
                                                            if(invoiceListVariables.selectedDomains[i] == item.domainID){
                                                                checked = true;
                                                                break;
                                                            }

                                                        }

                                                        return (
                                                            <div className={(index > 0 ? "form-check ml-2" : "form-check")} key={index}>
                                                                <input className="form-check-input" type="checkbox" checked={checked} value = {item.domainID} id={"domain" + index} onChange={(e) => this.invoiceLib.setDomain(e)} />
                                                                <label className="form-check-label" htmlFor={"domain" + index} >
                                                                    {item.domainName}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    
                                                    {data.allServices && data.allServices.map((item,index) => {

                                                        var checked = false;
                                                        for(let i in invoiceListVariables.selectedServices){
                                                            if(invoiceListVariables.selectedServices[i] == item.serviceID){
                                                                checked = true;
                                                                break;
                                                            }

                                                        }
                                                        
                                                        return (
                                                            <div className={(index > 0 ? "form-check ml-2" : "form-check")}  key={index}>
                                                                <input className="form-check-input" type="checkbox" value="" id={"service" + index} checked={checked} value = {item.serviceID} onChange={(e) => this.invoiceLib.setService(e)} />
                                                                <label className="form-check-label" htmlFor={"service" + index} >
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div className="card-body">

                                            <div className="text-right" style={{paddingBottom:"15px"}}><strong>Celkem:</strong> {data.totalInvoicesPrice} | <strong>70%:</strong> {Math.round((data.totalInvoicesPrice / 100) * 70 * 100) / 100} | <strong>30%:</strong> {Math.round((data.totalInvoicesPrice / 100) * 30 * 100) / 100} </div>
                            
                                            <div>
                                                <div className="data-list">
                                                    
                                                    <div className="data-list-item header">
                                                        <div className="data-list-item-content">
                                                            <div className="static">Číslo faktury</div>
                                                            <div className="text-center static">Jméno</div>
                                                            <div className="text-center static">Příjmení</div>
                                                            <div className="text-center">Email</div>
                                                            <div className="text-center static">Tel</div>
                                                            <div className="text-center static">Datum vložení</div>
                                                            <div className="text-center static">Datum splatnosti</div>
                                                            <div className="text-center static">Celková částka</div>
                                                            <div className="text-right static small">Možnosti</div>
                                                        </div>
                                                    </div>
                                                    
                                                    {data.allFilteredInvoices && data.allFilteredInvoices.map((item,index) => {

                                                                                                                                                                                
                                                        return (
                                                        
                                                            <div key={index} className="data-list-item-content">
                                                                <div className="static">{item.invoiceNumber}</div>
                                                                <div className="text-center static">{item.name}</div>
                                                                <div className="text-center static">{item.surname}</div>
                                                                <div className="text-center">{item.email}</div>
                                                                <div className="text-center static">{item.tel}</div>
                                                                <div className="text-center static">{item.dateAddStr}</div>
                                                                <div className="text-center static">{item.dueDateStr}</div>
                                                                <div className="text-center static">{item.totalPrice} {item.currency}</div>
                                                                <div className="text-right static small">
                                                                <a target="_blank" href={SERVER_URL + "/generate/invoice/" + item.invoiceID}><img title="tisk faktury" className="print-icon" src={notes} /></a>  
                                                                    
                                                                </div>
                                                            </div>
                                                                    
                                                        )
                                                        
                                                    })} 
 
                                                </div>
                                                
                                                {data.allFilteredInvoices && data.allFilteredInvoices.length == 0 ?
                                                    <NoItems text={"V tomto období se momentálně nenachází žádné faktury."} />
                                                :
                                                    <div className="text-center p-5">
                                                        <button className="btn btn-primary" onClick={() => fetchMore({
                                                            variables: {
                                                                offset: data.allFilteredInvoices.length
                                                            },
                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                if (!fetchMoreResult) return prev;
                                                                return Object.assign({}, prev, {
                                                                    allFilteredInvoices: [...prev.allFilteredInvoices, ...fetchMoreResult.allFilteredInvoices]
                                                                });
                                                            }
                                                        })}> Načíst další faktury </button>
                                                    </div> 

                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                    );
                                }
                            }
                        </Query>

                    </div>
                </div> 	

                <Modal
                    isOpen={showExport}
                    className="Modal__Bootstrap modal-dialog modal-lg"
                    closeTimeoutMS={150}
                    onRequestClose={() => this.closeExport()}
                >

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Export faktur</h4>
                        </div>
                        <div className="modal-body">
                            {loadingExport ? 
                                <Fragment>
                                    <Loading />
                                    <div className="progress" style={{height:"30px"}}>
                                        <div className="progress-bar" role="progressbar" style={{width:Math.round((completedCount / invoiceCount * 100 )) + "%"}} aria-valuenow={Math.round((completedCount / invoiceCount * 100 ))} aria-valuemin="0" aria-valuemax="100">{completedCount}/{invoiceCount}</div>
                                    </div>
                                </Fragment>
                                :
                                <div className="text-center pt-5 pb-5"><a style={{fontSize:"30px"}} href={SERVER_URL + "/Public/Invoices/Export/" + zipName}>STÁHNOUT FAKTURY</a></div>
                            }
                        </div>
                    </div>


                </Modal>
                
            </div>
        );

    }

}

export default withApollo(Customer);