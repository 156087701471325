/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací faktur
 */
import {GET_INVOICES,EXPORT_INVOICE,EXPORTED_INVOICES_TO_ZIP} from '../Queries/invoice.js';

class Invoice {

    constructor(parent){
        this.parent = parent;
    }

    setDateFrom(date){
        this.parent.setState({...this.parent.state,invoiceListVariables:{...this.parent.state.invoiceListVariables,dateFrom: date}});
    }
    setDateTo(date){
        console.log(date);
        this.parent.setState({...this.parent.state,invoiceListVariables:{...this.parent.state.invoiceListVariables,dateTo: date}});
    }

    setDomain(e){

        var value = e.target.value;
        var arr = [...this.parent.state.invoiceListVariables.selectedDomains];

        var index = -1;
        for(let i in arr){
            if(arr[i] == value){
                index = i;
            }
        }

        if(index != -1){
            arr = [...arr.slice(0,index),...arr.slice(index+1)];
        }else{
            arr.push(value);
        }

        this.parent.setState({invoiceListVariables:{...this.parent.state.invoiceListVariables, selectedDomains:arr}});
    }

    setService(e){

        var value = e.target.value;
        var arr = [...this.parent.state.invoiceListVariables.selectedServices];

        var index = -1;
        for(let i in arr){
            if(arr[i] == value){
                index = i;
            }
        }

        if(index != -1){
            arr = [...arr.slice(0,index),...arr.slice(index+1)];
        }else{
            arr.push(value);
        }

        this.parent.setState({invoiceListVariables:{...this.parent.state.invoiceListVariables, selectedServices:arr}});
    }

    invoiceExport(){

        this.parent.setState({showExport:true},async () => {

            var invoiceListVariables = {
                limit:1000000,
                offset:0,
                lang:"cz",
                dateFrom: this.parent.state.invoiceListVariables.dateFrom,
                dateTo: this.parent.state.invoiceListVariables.dateTo,
                selectedDomains:this.parent.state.invoiceListVariables.selectedDomains,
                selectedServices:this.parent.state.invoiceListVariables.selectedServices,
                
            }

            var invoices = await this.parent.props.client.query({ 
                query: GET_INVOICES,
                errorPolicy:"all",
                variables:invoiceListVariables, 
                fetchPolicy: 'network-only'
            });


            var invoiceCount = invoices.data.allFilteredInvoices.length;
            var completedCount = 0;

            this.parent.setState({invoiceCount,completedCount},() => {

                var arrCount = [];
                for(let i in invoices.data.allFilteredInvoices){

                    
                    this.parent.props.client.mutate({ 
                        mutation: EXPORT_INVOICE,
                        errorPolicy:"all",
                        variables:{
                            invoiceID: invoices.data.allFilteredInvoices[i].invoiceID,
                            domainID:  invoices.data.allFilteredInvoices[i].domainID
                        },
                    }).then((invoiceNumber) => {

                        arrCount.push(invoiceNumber.data.exportInvoice);
                          
                    });

                }

                var inerval = setInterval(()=> {

                    this.parent.setState({completedCount:arrCount.length},() => {
                        if(this.parent.state.completedCount == invoiceCount){
                            
                            clearInterval(inerval);

                            this.parent.props.client.mutate({ 
                                mutation: EXPORTED_INVOICES_TO_ZIP,
                                errorPolicy:"all",
                                variables:{
                                    invoices:arrCount  
                                },
                            }).then((zipName) => {
        
                                setTimeout(() => {
                                    this.parent.setState({loadingExport:false,zipName:zipName.data.exportedInvoicesToZip});
                                },1000);
                                  
                            });

                               
                        }
                    });

                },500);


            });


        });

    }

}

export default Invoice;